@import (reference) "../../../../styles/less/colors.less";

@onlineData: rgb(32, 99, 151);
@offlineData: #000;

.analyticsLegendItem {
  cursor: pointer;
}

.analyticsLegendItem + .analyticsLegendItem {
  margin-left: 15px;
}

.analyticsLegendItemBox {
  margin-right: 5px;

  > span {
    display: inline-block;
  }
}

.analyticsLegendItemBox_filled {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.analyticsLegendItemBox_dotted {
  width: 10px;
  height: 4px;
  border-top: 2px dotted;
}

.analyticsLegendItemText {
  font-size: 12px;
  font-weight: 600;
  color: @color-text-secondary-on-light;
}

.gas-chart-legend-item-onlinedata {
  background: @onlineData;
}

.gas-chart-legend-item-offlinedata {
  background: @offlineData;
}
