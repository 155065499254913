.ltc-tab {
  height: calc(100vh - 230px);
  padding: 1.5em;
  & > .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    & > .bootstrap-row {
      &:nth-child(2) {
        flex: 1;
      }
      &:not(:last-child) {
        margin-bottom: 1em;
        min-height: 220px;
      }
    }
  }
  .card {
    height: 100%;
    overflow: hidden;
    .card-body {
      .widget-message {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .spinner {
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
