@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

.ltc-percentage-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 1em;
    font-size: @font-size-medium;
    line-height: 20px;
    color: @color-gray-90;
    font-weight: 600;
  }

  &__percent {
    font-size: 32px;
    color: @color-gray-90;
    font-weight: 600;
    padding-left: 0.25em;
    padding-right: 0.25em;
  }

  .ltc-bg-warning {
    background-color: @color-status-yellow;
  }

  .ltc-bg-alarm {
    background-color: @color-status-orange;
  }
}
