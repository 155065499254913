@import "~antd/dist/antd.less";
@import (reference) "./colors.less";
@import (reference) "./variables.less";

@font-family: "Open Sans", Helvetica, Arial, sans-serif;
@primary-color: @color-primary;

@error-color: @color-status-red;
@warning-color: @color-status-orange;
@info-color: @color-status-blue;
@success-color: @color-status-green;

@screen-xs: @screen-size-xs;
@screen-sm: @screen-size-sm;
@screen-md: @screen-size-md;
@screen-lg: @screen-size-lg;
@screen-xl: @screen-size-xl;
@screen-xxl: @screen-size-xxl;

@btn-primary-hover-background-color: fade(@primary-6, 15%);
@btn-primary-hover-border-color: fade(@primary-5, 15%);
@btn-primary-hover-color: fade(@primary-4, 50%);

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.ant-btn-primary {
    &[disabled]:hover,
    &[disabled] {
      background-color: @btn-primary-hover-background-color;
      border-color: @btn-primary-hover-border-color;
      color: @btn-primary-hover-color;
    }
  }
}

@text-color: @color-text-primary-on-light;
@text-color-secondary: @color-text-secondary-on-light;

@text-color-dark: @color-text-primary-on-dark;
@text-color-secondary-dark: @color-text-secondary-on-dark;

@menu-inline-submenu-bg: @color-gray-0;
@menu-highlight-color: @color-gray-100;
@menu-item-active-bg: @color-widget-background;

@drawer-bg: @color-widget-background;

.ant-drawer-content-wrapper {
  padding-top: 1px;
}

.ant-slider {
  .ant-slider-rail {
    width: calc(100% + 6px);
    margin-left: -3px;
  }
}

@zindex-modal: 2000;
@zindex-modal-mask: 2000;
@zindex-message: 2010;
@zindex-notification: 2010;
@ant-popover: 2030;
@zindex-popover: 2030;
@zindex-dropdown: 2050;
@zindex-picker: 2050;
@zindex-popoconfirm: 2060;
@zindex-tooltip: 2070;
@zindex-image: 2080;
