@import (reference) "./colors.less";
@import (reference) "./legacy.less";
@import (reference) "./variables.less";

.asset-risk.high {
  color: @color-risk-high !important;
}

.asset-risk.medium {
  color: @color-risk-medium !important;
}

.asset-risk.low {
  color: @color-risk-low !important;
}

.asset-risk.unknown {
  color: @color-risk-unknown !important;
}

.asset-risk-bg.high {
  background-color: @color-risk-high !important;
}

.asset-risk-bg.medium {
  background-color: @color-risk-medium !important;
}

.asset-risk-bg.low {
  background-color: @color-risk-low !important;
}

.asset-risk-bg.unknown {
  background-color: @color-risk-unknown !important;
}

.asset-risk-border.high {
  border-color: @color-risk-high !important;
}

.asset-risk-border.medium {
  border-color: @color-risk-medium !important;
}

.asset-risk-border.low {
  border-color: @color-risk-low !important;
}

.asset-risk-border.unknown {
  border-color: @color-risk-unknown !important;
}

.asset-risk-bar-value.high {
  background-color: @color-risk-high;
}

.asset-risk-bar-value.medium {
  background-color: @color-risk-medium;
}

.asset-risk-bar-value.low {
  background-color: @color-risk-low;
}

.asset-risk-bar-value.unknown {
  background-color: @color-risk-unknown;
}

.asset-risk-trend() {
  .trend-chart {
    position: relative;
    .indicators {
      border-top: solid 1px @color-gray-10;
      display: flex;
      justify-content: space-around;
      margin-top: 10px;

      .indicator {
        margin: 10px;
        width: 100px;

        .value {
          font-size: @font-size-large;
        }

        .c-label:extend(.default-grey-label) {
        }
      }
    }

    .c3-custom-legend.c3-legend {
      margin-right: 25px;
    }
    .y-axis-label {
      color: @color-primary;
      font-size: 8.5pt;
      position: absolute;
      top: 5px;
      left: 21px;
    }

    .c3-line {
      stroke-width: 2px;
    }

    .c3-area {
      opacity: 0.9;
    }
  }
}
