@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

.ltc-latest-update {
  &__title {
    font-size: @font-size-medium;
    line-height: 20px;
    color: @color-gray-90;
    font-weight: 600;
  }

  &__date {
    font-weight: 600;
    font-size: @font-size-small;
    font-weight: 500;
    color: @color-text-primary-on-light;
  }

  &__counts {
    margin-top: 1em;
  }

  &__label {
    padding: 0;

    span {
      color: @color-text-secondary-on-light;
      font-size: @font-size-base;
      font-weight: 500;
    }
  }

  &__value {
    padding: 0;

    span {
      font-weight: 600;
      font-size: @font-size-base;
      font-weight: 500;
      color: @color-text-primary-on-light;
    }
  }
}
