.duval-pentagon-svg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.duval-pentagon-container {
  position: relative;
  width: 100%;
}

.duval-pentagon-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
