@import (reference) "../../../../../../styles/less/variables.less";
@import (reference) "../../../../../../styles/less/colors.less";

.bushing-static-legend {
  &__item {
    display: inline-flex;
    margin-right: 15px;

  }
  &__item-box {
    margin-right: 5px;

    &--dotted {
      width: 15px;
      height: 4px;
      border-radius: unset;
      display: flex;
      margin-top: @spacing-medium;
    }

    .PowerFactor-box {
      color: @color-blue-60;
      border-top: 3px dotted;
    }

    .Capacitance-box {
      color: @color-status-orange;
      border-top: 3px dotted;
    }

    .InstallDate-box {
      width: 10px;
      color: @color-gray-100;
      border-top: 2px dotted;
    }
  }
  
  &__item-text {
    font-size: @font-size-small;
    font-weight: 600;
    color: @color-gray-70;
  }
}
