@import (reference) "./colors.less";
@import (reference) "./variables.less";

.c3 {
  svg {
    font-family: @font-family;
    font-size: @font-size-x-small;

    .c3-axis-x tspan,
    .c3-axis-y tspan,
    .c3-axis-y2 tspan,
    .c3-axis-x-label,
    .c3-axis-y-label {
      fill: @color-gray-70;
    }

    .c3-axis-x .tick text,
    .c3-axis-y .tick text,
    .c3-axis-y2 .tick text,
    .c3-axis-x-label,
    .c3-axis-y-label {
      font-size: 11px;
      font-weight: 600;
    }

    .c3-axis path,
    .c3-axis line {
      stroke: @color-gray-20 !important;
    }

    .c3-empty {
      fill: @color-gray-50;
      text-transform: uppercase;
    }

    .c3-grid {
      .c3-xgrids,
      .c3-ygrids {
        line {
          stroke: @color-gray-20;
        }
      }
    }
    
    .c3-legend-item,
    .c3-legend-item text {
      font-size: @font-size-base;
    }
  }
}
