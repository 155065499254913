@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

.history-switching-chart-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__title {
    padding: 0 0.5em 1.5em;
    font-size: @font-size-medium;
    line-height: 20px;
    color: @color-gray-90;
    font-weight: 600;
  }
}
