@import (reference) "../../../../styles/less/colors.less";

@leftAxisColor: @color-status-blue;
@rightAxisColor: @color-status-orange;

@gasCondition1: @color-status-green;
@gasCondition2: @color-status-yellow;
@gasCondition3: @color-status-orange;
@gasCondition4: @color-status-red;

.detail-page-new .widgets {
  .bushing-offline {
    .c3 {
      svg {
        max-height: 210px;
        min-height: 210px;
        overflow: visible !important;
        font: 6px;
        font-weight: 600;
      }

      min-height: 180px;
    }
  }

  .dga {
    .c3 {
      svg {
        max-height: 180px;
        min-height: 180px;
        overflow: visible !important;
        font: 6px;
        font-weight: 600;
      }

      min-height: 180px;
    }
  }

  .bushing-offline,
  .dga {
    .c3-axis {
      font: 11px;
      font-weight: 500;
    }

    .disabled {
      .c3-axis {
        opacity: 0.5 !important;
      }
    }

    .c3-target-treshold {
      stroke-dasharray: 4, 4;

      .c3-line {
        stroke-width: 2px;
      }
    }

    .c3-target-line-hidden .c3-line {
      display: none;
    }

    .c3-target-points-hidden .c3-circles .c3-circle {
      display: none;
    }

    .c3-tooltip td > span {
      border-radius: 5px;
    }

    .c3-axis-y-label {
      fill: @leftAxisColor !important;
    }

    .c3-axis-y2-label {
      fill: @rightAxisColor !important;
      stroke: @rightAxisColor !important;
      stroke-width: 0.04em !important;
      transform: translate(0px, 10px);
      font-size: 12px !important;
    }

    .c3 {
      .c3-axis-y {
        font-size: 12px;

        text {
          fill: @leftAxisColor !important;

          tspan {
            fill: inherit;
          }
        }
      }

      .c3-axis-y2 {
        font-size: 11px;
        font-weight: 400;

        text {
          fill: @rightAxisColor !important;

          tspan {
            fill: inherit;
          }
        }
      }
    }

    .analytics-chart-header {
      color: @color-text-secondary-on-light;
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 12px;
      justify-content: space-between;

      div:first-child {
        flex-grow: 1;
        padding-left: 14px;
        font-size: 18px;
      }

      div:last-child {
        flex-grow: 4;
        padding-right: 10px;
        font-size: 12px;
        padding-top: 6px;
        text-align: right;
      }
    }
  }

  .standard-oil-tests {
    .c3 {
      max-height: none !important;

      svg {
        max-height: 210px;
        min-height: 210px;
        overflow: visible !important;
        font: 6px;
        font-weight: 600;
      }

      min-height: 180px;
    }

    .disabled {
      .c3-axis {
        opacity: 0.5 !important;
        font: 11px;
        font-weight: 500;
      }
    }

    .c3-axis {
      font: 11px;
      font-weight: 500;
    }

    g[class*="c3-target-"][class$="-max"],
    g[class*="c3-target-"][class$="-min"],
    g[class*="c3-target-"][class$="-fair"],
    g[class*="c3-target-"][class$="-poor"] {
      stroke-dasharray: 4, 4;

      .c3-line {
        stroke-width: 2px;
      }
    }

    // ----------

    .c3-tooltip td > span {
      border-radius: 5px;
    }

    .c3-axis-y-label {
      fill: @color-status-blue !important;
    }

    .c3-axis-y2-label {
      fill: @color-status-orange !important;
      stroke: @color-status-orange !important;
      stroke-width: 0.04em !important;
    }

    .c3-axis-y {
      font-size: 12px;

      text {
        fill: @leftAxisColor !important;

        tspan {
          fill: inherit;
        }
      }
    }

    .c3-axis-y2 {
      font-size: 11px;
      font-weight: 500;

      text {
        fill: @color-status-orange !important;

        tspan {
          fill: inherit;
        }
      }
    }
  }
}

.c3-region.gas-condition1 {
  fill: @gasCondition1;
}

.c3-region.gas-condition2 {
  fill: @gasCondition2;
}

.c3-region.gas-condition3 {
  fill: @gasCondition3;
}

.c3-region.gas-condition4 {
  fill: @gasCondition4;
}

.analytics-chart-header {
  color: @color-text-secondary-on-light;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 12px;
  justify-content: space-between;

  div:first-child {
    flex-grow: 1;
    padding-left: 14px;
    font-size: 18px;
  }

  div:last-child {
    flex-grow: 4;
    padding-right: 10px;
    font-size: 12px;
    padding-top: 6px;
    text-align: right;
  }
}
