@import (reference) "../../../../../../styles/less/colors.less";

.detail-page-new .widgets .bushing-offline {
  height: auto;
  min-height: 840px;
  padding: 15px 15px 0 15px;
  .bushing-row {
    clear: both;
    border: 1px solid @color-gray-10;
    padding: inherit;
    margin: 0 0 15px 0;

    .bushing-col {
      display: inline-block;
      margin: auto;
      width: 33%;
    }
  }
}
