// Copyright 2016-2023 Hitachi Energy. All rights reserved.
@import (reference) "styles/less/colors.less";

.asset-ribbon {
  height: 84px;
  max-height: 84px;
  background-color: @color-gray-10;

  .ant-col {
    min-height: 100%;
    height: 100%;
  }

  .justify-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-section {
    height: 100%;
    padding: 0.75em 1em 0.75em 1em;
  }

  &-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 0.5em;
  }
}
