@import (reference) "styles/less/colors.less";

.asset-detail-replacement-score {
  &-grid-row {
    .ant-col {
      height: 100%;
    }
  }

  &-total {
    & > span {
      margin-right: 0.25em;
    }
  }

  .replacement-score {
    &.warning {
      h2 {
        color: @color-status-orange;
      }
    }
  }

  .family-info-popover-icon {
    font-size: 16px;
  }

  &-grid-row {
    .replacement-score {
      .ant-typography {
        line-height: 1;
      }
    }
  }

  &-out-of-column {
    display: flex;
    flex-direction: column;
    .ant-typography {
      line-height: 0.8;
    }
  }

  .family-info-popover-off {
    opacity: 0.1;
    pointer-events: none;
  }
}
