@import (reference) "../../../../../../styles/less/colors.less";

@customer-expected-life-region: #47a092;
@customer-expected-life-threshold: #47a092;
@manufacturer-expected-life-region: #7c71a7;
@manufacturer-expected-life-threshold: #7c71a7;
@age-bar-stroke-color: rgba(46, 146, 250);
@adjusted-age-bar-stroke-color: rgba(32, 99, 152);

.aging-visualization-chart {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);

  .resize-wrapper {
    flex-grow: 1;
  }

  .c3-axis-y-label {
    transform: translate(28px, -17px);
    color: black;
    font-weight: 600;
  }

  .c3-axis-x .tick {
    display: none;
  }

  .c3-ygrid {
    stroke: black;
    stroke-dasharray: 0;
  }

  .c3-ygrid-lines {
    .c3-ygrid-line {
      stroke-dasharray: 10 10;

      &.customer-expected-life-threshold {
        line {
          stroke: @customer-expected-life-threshold;
          stroke-width: 3px;
        }
      }

      &.manufacturer-expected-life-threshold {
        line {
          stroke: @manufacturer-expected-life-threshold;
          stroke-width: 3px;
        }
      }

      &.age-threshold,
      &.adjusted-age-threshold {
        line {
          stroke-dasharray: 0;
          stroke-width: 1px;
          stroke: fade(@color-gray-90, 75%);
        }
      }
    }
  }

  .c3-region {
    &.customer-expected-life-region {
      fill: @customer-expected-life-region;
    }
  }

  .c3-chart .c3-chart-bars .c3-chart-bar .c3-bars {
    .c3-bar-0 {
      stroke: @age-bar-stroke-color !important;
      stroke-width: 3px;
    }

    .c3-bar-1 {
      stroke: @adjusted-age-bar-stroke-color !important;
      stroke-width: 3px;
    }
  }
}
