@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

.ltc-operations {
  &.chart-legend {
    margin: -10px 0 10px 0px;
    display: block;
    &__item {
      cursor: default;
      display: flex;
      align-items: center;
    }

    &__item-box {
      position: relative;
      width: 25px;
      height: 13px;
      border-radius: 4px;
      margin-right: 0.5em;
      opacity: 0.6;
      display: flex;
      align-items: center;

      .ltc-legend-grid-line {
        width: 100%;
        border-top: 2px dashed black;
        opacity: 0.6;
      }
    }
    &__item-text {
      font-weight: 600;
      font-size: @font-size-small;
      font-weight: 500;
      color: @color-text-secondary-on-light;
    }
  }
}
