@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

.grid-custom.dga-operations {
  border: none;
  width: 100%;

  .grid-header,
  .grid-body {
    .grid-row {
      .grid-cell {
        &.name {
          width: 44%;
          min-width: 44%;
          max-width: 44%;
        }
        &.value {
          width: 28%;
          min-width: 28%;
          max-width: 28%;
        }
      }
    }
  }

  .grid-header {
    .grid-row {
      align-items: center;
      background-color: white;
      border: none;
      .grid-cell {
        min-height: 44px;
        flex-direction: column;
        align-items: flex-start;

        &.dga-operations-header {
          &__title {
            position: relative;
            span {
              font-size: @font-size-medium;
              line-height: 20px;
              color: @color-gray-90;
              font-weight: 600;
              position: absolute;
              top: 25%;
            }
          }
          &__date-header {
            font-weight: 600;
            font-size: @font-size-small;
            font-weight: 500;
            color: @color-text-secondary-on-light;
          }
        }
      }
    }
  }

  .grid-body {
    .grid-row {
      &:hover {
        cursor: default;
        background-color: inherit;
      }
      &__ltc-dga-row {
        .grid-cell {
          &.name {
            color: @color-text-secondary-on-light;
          }
          &.value {
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
    .grid-row:nth-child(odd) {
      background-color: @color-gray-10;
    }
    .grid-row:first-of-type,
    .grid-row + .grid-row {
      border: none;
    }
  }
}
