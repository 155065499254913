@import (reference) "../../../../../styles/less/colors.less";

.c3.asset-risk-chart {
  .c3-chart .c3-chart-lines {
    .no-cursor-here() {
      cursor: default !important;
      pointer-events: none !important;
    }

    .invisible() {
      .no-cursor-here();
      display: none !important;
      stroke: none !important;
      fill: none !important;
      opacity: 0 !important;
    }

    .area-base() {
      opacity: 1 !important;
      .no-cursor-here();
    }

    .c3-target-risk-low-stack {
      .c3-area {
        .area-base();
        fill: @color-risk-low !important;
      }
    }

    .c3-target-risk-med-stack {
      .c3-area {
        .area-base();
        fill: @color-risk-medium !important;
      }
    }

    .c3-target-risk-hig-stack {
      .c3-area {
        .area-base();
        fill: @color-risk-high !important;
      }
    }

    .c3-target-risk-low-stack,
    .c3-target-risk-med-stack,
    .c3-target-risk-hig-stack {
      .c3-lines {
        .c3-line {
          .invisible();
        }
      }

      .c3-circles {
        cursor: none !important;
        pointer-events: none !important;
        .c3-circle {
          .invisible();
        }
      }
    }

    .c3-target-importance-low,
    .c3-target-importance-high {
      .c3-line {
        opacity: 1 !important;
        .no-cursor-here();
      }

      .c3-circles {
        .c3-circle {
          .invisible();
        }
      }
    }

    .c3-target-other-assets {
      .c3-circles {
        .c3-circle {
          fill: black !important;
          stroke: none;
          shape-rendering: geometricPrecision;
        }
      }
    }

    .c3-target-current-asset {
      .c3-circles {
        .c3-circle {
          fill: white !important;
          opacity: 1 !important;
          shape-rendering: geometricPrecision;
          stroke: black;
        }
      }
    }

    [class^="c3-target-cluster"] {
      .c3-circles {
        .c3-circle {
          stroke: none;
          shape-rendering: geometricPrecision;
        }
      }
    }

    .c3-target-cluster-tier1 {
      .c3-circles {
        .c3-circle {
          opacity: 0.6 !important;
        }
      }
    }

    .c3-target-cluster-tier2 {
      .c3-circles {
        .c3-circle {
          opacity: 0.7 !important;
        }
      }
    }

    .c3-target-cluster-tier3 {
      .c3-circles {
        .c3-circle {
          opacity: 0.8 !important;
        }
      }
    }

    .c3-target-cluster-tier4 {
      .c3-circles {
        .c3-circle {
          opacity: 0.9 !important;
        }
      }
    }

    .c3-target-prognostic {
      .c3-circles {
        .c3-circle {
          stroke: @color-primary;
        }

        .c3-circle:nth-child(1) {
          opacity: 0.9 !important;
          stroke-width: 0.5px;
        }
        .c3-circle:nth-child(2) {
          opacity: 0.8 !important;
          stroke-width: 1px;
        }
        .c3-circle:nth-child(3) {
          opacity: 0.75 !important;
          stroke-width: 1.5px;
        }
        .c3-circle:nth-child(4) {
          opacity: 0.7 !important;
          stroke-width: 2.25px;
        }
        .c3-circle:nth-child(5) {
          opacity: 0.65 !important;
          stroke-width: 3px;
        }
      }
    }
  }

  .c3-grid .c3-xgrid-focus {
    visibility : hidden !important;
 }

  .c3-grid.c3-grid-lines {
    .c3-xgrid-lines,
    .c3-ygrid-lines {
      .c3-xgrid-line,
      .c3-ygrid-line {
        line {
          stroke: white;
          stroke-width: 1px;
          stroke-dasharray: 4;
          opacity: 0.7 !important;
        }
      }
    }
  }

  &.asset-risk-chart-grayscale {
    .c3-chart .c3-chart-lines {
      .c3-area-risk_low_stack {
        fill: @color-gray-30 !important;
      }

      .c3-area-risk_med_stack {
        fill: @color-gray-30 !important;
      }

      .c3-area-risk_hig_stack {
        fill: @color-gray-40 !important;
      }
    }
  }
}
