@import (reference) "../../../../../styles/less/colors.less";

.c3.asset-risk-chart {
  table.c3-tooltip.asset {
    tr.c3-tooltip-name-importance,
    tr.c3-tooltip-name-score {
      td > span {
        background-color: white;
      }
    }

    tr.c3-tooltip-name-risk {
      &.c3-tooltip-name-risk-high td > span {
        background-color: @color-risk-high;
      }

      &.c3-tooltip-name-risk-medium td > span {
        background-color: @color-risk-medium;
      }

      &.c3-tooltip-name-risk-low td > span {
        background-color: @color-risk-low;
      }

      &.c3-tooltip-name-risk-unknown td > span {
        background-color: @color-risk-unknown;
      }
    }
  }

  &.asset-risk-chart-grayscale {
    table.c3-tooltip {
      tr.c3-tooltip-name-risk {
        &.c3-tooltip-name-risk-high td > span {
          background-color: #919191 !important;
        }

        &.c3-tooltip-name-risk-medium td > span {
          background-color: #b7b7b7 !important;
        }

        &.c3-tooltip-name-risk-low td > span {
          background-color: #b5b5b5 !important;
        }
      }
    }
  }
}
