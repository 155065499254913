.family-info-popover {
  .ant-popover-inner-content {
    position: relative;
    display: block;
    width: 320px;
    min-height: 100px;
    overflow-x: hidden;
    overflow-y: hidden;

    .error-message {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .family-info-popover-container {
      .labeled-row {
        .value:extend(.paragraph-on-light) {
          > span > span {
            display: inline-block;
            word-break: break-all;
          }
        }
      }
      .large-content:extend(.paragraph-on-light) {
        font-weight: 600;
        padding: 2px;
        display: inline-block;
        word-break: break-all;
      }

      .box-plot {
        padding-top: 15px;
      }
    }

    .compare-button-container {
      display: flex;
      justify-content: center;
      margin-top: 15px;

      text-align: center;

      button {
        .icon {
          margin-right: 5px;
        }
      }
    }

    .spinner {
      margin: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
