@import (reference) "../../../../../../styles/less/colors.less";

.ltc-operations-chart {
  .tick line {
    display: none;
  }

  .c3-ygrid {
    stroke: black !important;
    opacity: 0.1 !important;
    stroke-dasharray: 0;
  }

  .c3-ygrid-line {
    &.ltc-warning line {
      stroke-dasharray: 8;
      stroke: @color-status-yellow;
      stroke-width: 3;
    }

    &.ltc-alarm line {
      stroke-dasharray: 8;
      stroke: @color-status-orange;
      stroke-width: 3;
    }
  }

  .c3-regions rect.ltc-warning {
    fill: @color-status-yellow;
  }

  .c3-regions rect.ltc-alarm {
    fill: @color-status-orange;
  }

  .c3-chart-bars .c3-chart-bar path {
    stroke: @color-blue-60 !important;
    stroke-width: 1px;
    stroke-linecap: round;
    fill: @color-blue-50 !important;
    fill-opacity: 0.6;
  }
}
