.parameters-histogram {
  &.container {
    min-height: 140px;
    min-width: 500px;
    padding-left: 0px;
    padding-right: 0px;

    .widget-message {
      height: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .parameter-histogram {
      &.container {
        padding-left: 10px;
        padding-right: 5px;
      }
    }
  }
}
