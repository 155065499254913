@import (reference) "../../../../styles/less/colors.less";
@import (reference) "../../../../styles/less/legacy.less";
@import (reference) "../../../../styles/less/variables.less";

.homepage .open-issues {
  .chart-title {
    font-size: @font-size-x-large;
    fill: @color-text-primary-on-light;
    font-weight: 600;
  }

  .chart-subtitle:extend(.default-grey-label) {
    fill: @color-text-primary-on-light;
  }

  .grid {
    margin: 20px 0 10px 0;

    > .c-row:extend(.paragraph-on-light) {
      border-top: solid 1px @color-gray-10;
      margin-top: 10px;
      padding-top: 10px;

      > .description {
        word-wrap: break-word;
      }

      > .details {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        > .asset {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      > .time {
        color: @color-status-orange;
        text-align: right;
        padding-top: 5px;
      }
    }
  }

  .c3 .c3-legend-item text {
    font-size: @font-size-base;
    fill: @color-text-primary-on-light;
    font-weight: normal;
  }

  // IE - fix
  // https://github.com/c3js/c3/issues/2528
  .c3-target[style*="opacity: 1"] {
    display: block !important;
  }
}
