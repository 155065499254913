@import (reference) "../../../../../styles/less/colors.less";
@import (reference) "../../../../../styles/less/variables.less";

.details-page-issues {
  font-size: 15px;

  .issue {
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 2px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    margin: 2px;
    margin-top: 12px;
    position: relative;

    .issue-compact,
    .issue-details {
      padding: 18px;

      .issue-header-status {
        align-items: center;
        display: flex;

        .issue-icon {
          margin-top: -6px;

          .manually-created {
            margin-right: 12px;
          }
        }
      }

      .input-select {
        margin-top: 0;
        margin-bottom: 0;
      }

      .tooltip-maintenance-priority {
        font-size: @font-size-small;
        font-weight: 600;
      }

      .tooltip-created-date {
        font-size: @font-size-small;

        .change-label,
        .change {
          padding: 4px 0;
        }

        .change-label {
          font-weight: 600;
        }

        .change {
          display: flex;
          font-weight: normal;
          margin-left: 8px;

          > span:first-of-type {
            flex-grow: 1;
          }

          > span + span {
            margin-left: 8px;
          }
        }

        hr {
          background-color: @color-gray-40;
          margin-left: 8px;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    .issue-compact {
      .issue-header {
        display: flex;
        justify-content: space-between;

        .issue-header-date {
          color: @color-gray-90;
          font-weight: 600;
        }
      }

      .issue-title,
      .issue-condition {
        margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .issue-title {
        color: @color-gray-90;
        font-weight: 600;
      }
    }

    .issue-details {
      .issue-header {
        .issue-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button {
            display: flex;
            align-items: center;

            .icon {
              margin-right: 8px;
            }
          }

          button + button {
            margin-left: 8px;
          }
        }

        .issue-header-main {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > div {
            flex-grow: 1;
            flex-basis: 0;
          }

          .issue-header-date {
            color: @color-gray-90;
            display: flex;
            font-weight: 600;
            justify-content: center;
          }
        }

        .issue-header-title {
          overflow: auto;
        }

        &.view {
          background-color: @color-gray-10;
          padding: 12px 18px;
        }

        &.readonly {
          .issue-header-main {
            .issue-header-date {
              justify-content: flex-end;
              font-weight: 600;
            }
          }
        }
      }

      .issue-urgency {
        font-weight: 600;
      }

      .value {
        margin-top: 8px;
        word-break: break-word;
        white-space: pre-wrap;
      }

      &.view {
        > div + div {
          margin-top: 24px;
          padding: 0 18px;
        }
      }

      &.create,
      &.edit {
        > div {
          .actions {
            margin-top: 24px;
          }
        }
      }
    }

    .overlay {
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }

  .issue:not(.active) {
    cursor: pointer;
  }

  .issue.disabled {
    cursor: not-allowed;
  }

  .issue:not(.disabled).active,
  .issue:not(.disabled):hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  }
}
