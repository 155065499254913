@import (reference) "../../../../../styles/less/colors.less";

@leftAxisColor: @color-status-blue;
@rightAxisColor: @color-status-orange;

.detail-page-new .widgets .duval-triangle {
  position: relative;
  margin: 0 8px 0 8px;
  border: 1px solid @color-gray-10;
  padding: 0 4px 0 4px;
  min-height: 680px;

  svg {
    text {
      font-weight: 600;
      color: @color-text-primary-on-black;
    }
  }

  .c-tooltip {
    position: fixed;
    width: auto;
    height: auto;
    background-color: white;
    pointer-events: none;
    display: none;
    font-size: 13px;
    border: 1px solid #aaa;
    color: @color-text-primary-on-light;
    padding: 0;
    z-index: 10;

    table {
      width: calc(~"100% + 4px");
      margin: -2px;
      border: 0;

      tr {
        &:not(:first-child) {
          td {
            border-top: 1px solid #aaa;
          }
        }
      }

      td {
        margin: 0;
        border: 0;
        padding: 0 10px 0 10px;
        white-space: nowrap;

        &:first-child {
          width: 40%;
        }

        &:not(:last-child) {
          border-right: 1px dashed #aaa;
        }
      }
    }

    .header {
      box-sizing: border-box;
      padding: 2px;
      padding-left: 4px;
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: white;
      background-color: #aaa;
      font-weight: 600;
    }

    .left {
      text-align: left;
    }

    .right {
      text-align: right;
      padding-right: 5px;
    }
  }

  .ant-collapse {
    position: absolute;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    top: 465px;

    .ant-collapse-item {
      .ant-collapse-header {
        border: 2px solid @color-status-yellow;
        display: flex;
        flex-direction: row;
        align-items: center;

        i {
          font-size: 22px;
        }
      }

      .ant-collapse-content {
        max-height: 0px;
      }
    }

    .ant-collapse-item.ant-collapse-item-active {
      box-shadow: 0px 4px 4px fade(black, 25%);

      .ant-collapse-header {
        background-color: @color-status-yellow;
        box-shadow: 0px 1px 5px fade(black, 25%);

        .duval-triangle-warning-icon {
          color: black;
        }
      }

      .ant-collapse-content {
        min-height: 140px;
        height: 140px;
        white-space: pre-line;
        font-size: 12px;
        color: black;
        overflow-y: scroll;
        .duval-triangle-note {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          span {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .duval-triangle-header {
    color: @color-text-secondary-on-light;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 12px;
    justify-content: space-between;
    text-align: left;

    flex-grow: 1;
    font-size: 18px;
    margin-top: 10px;
  }

  .duval-triangle-note.safari {
    i.ant-collapse-arrow {
      transform: translateY(-30%);
    }
  }

  .legend {
    font-size: 12px;
    margin-top: 80px;
  }
}
