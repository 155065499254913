@import (reference) "../../../../../styles/less/colors.less";

@bushing-warning-color: #ffd800;
@bushing-alert-color: #ff7300;
@bushing-y-legend-color: #000000;
@axis-color: #000000;

.bushing-weekly-chart {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);

  .resize-wrapper {
    flex-grow: 1;
  }

  .c3-axis-y-label {
    transform: rotate(0);
    transform: translate(-8px, 25px);
  }

  .c3-xgrid,
  .c3-ygrid {
    stroke: black;
    stroke-dasharray: 0;
  }

  .c3-region {
    &.alert-area {
      fill: @bushing-alert-color;
    }

    &.warning-area {
      fill: @bushing-warning-color;
    }

    &.current-forecast-area {
      fill: @color-blue-50;
    }
  }

  .c3-ygrid-lines {
    .c3-ygrid-line {
      stroke-dasharray: 10 10;
      stroke-width: 2px;

      &.alert-line {
        line {
          stroke: @bushing-alert-color;
        }
      }

      &.warning-line {
        line {
          stroke: @bushing-warning-color;
        }
      }
    }
  }

  .c3-line {
    stroke-width: 2px;
  }

  .c3-axis-y tspan,
  .c3-axis-y2 tspan {
    fill: @bushing-y-legend-color;
  }

  .c3-axis-x,
  .c3-axis-y {
    path,
    line {
      stroke: @bushing-y-legend-color;
    }
  }

  .c3-axis {
    path {
      stroke: @axis-color !important;
    }
    line {
      stroke: @axis-color;
    }
  }

  .bushing-weekly-legend {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 20px;

    .chart-legend {
      margin: 0;
      margin-right: 64px;
    }
  }
}
