@import (reference) "../../../../../styles/less/colors.less";

@pilot-cell-color: #7c71a7;
@reference-color: #7c71a7;

.battery-conductance-chart {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);

  .resize-wrapper {
    flex-grow: 1;
  }

  .c3-axis-y-label {
    transform: rotate(0);
    transform: translate(-4px, 36px);
  }

  .c3-axis-x-label {
    transform: translate(20px, 0px);
  }

  .c3-xgrid,
  .c3-ygrid {
    stroke: black;
    stroke-dasharray: 0;
  }

  .c3-ygrid-lines {
    .c3-ygrid-line {
      stroke-dasharray: 10 10;
      stroke-width: 2px;

      &.warning-level {
        line {
          stroke: @color-status-yellow;
        }

        text {
          fill: @color-status-yellow;
        }
      }

      &.alert-level {
        line {
          stroke: @color-status-orange;
        }

        text {
          fill: @color-status-orange;
        }
      }

      &.reference-level {
        line {
          stroke: @reference-color;
        }

        text {
          fill: @reference-color;
        }
      }
    }
  }

  .c3-region {
    &.alert-area {
      fill: @color-status-orange;
    }

    &.warning-area {
      fill: @color-status-yellow;
    }
  }

  .c3-chart .c3-chart-bars .c3-chart-bar .c3-bars .c3-bar.pilot-cell {
    stroke: @pilot-cell-color !important;
    stroke-width: 3px;
  }
}
