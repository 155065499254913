.widgets {
  .bushing-offline {
    .analyticsLegend {
      margin: 15px;
      margin-top: 0px;
    }
  }

  .standard-oil-tests {
    .analyticsLegend {
      margin-top: -20px;
      font-size: 10px;
      padding: 0 10px;
    }
  }

  .analyticsLegend {
    margin-top: -20px;
    position: relative;
    font-size: 10px;
    padding: 0 10px;

    .noactive {
      opacity: 0.4;
    }

    .active {
      opacity: 1;
    }

    .disabled {
      opacity: 0.2;
    }
  }
}
