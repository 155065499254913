.c3-custom-legend.c3-legend {
  margin-bottom: 12px;
  position: static;

  > div {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}

.c3-custom-legend {
  .c3-legendItem {
    cursor: pointer;
    font-size: 14px;
    margin: 0 12px;

    .c3-legendBox {
      display: inline-block;
      width: 12px;
      height: 12px;
    }

    .c3-legendText {
      margin-left: 8px;
    }
  }
}
