@import (reference) "../../../../../../styles/less/colors.less";

.aging-operations-chart {
  .tick line {
    display: none;
  }

  .c3-ygrid {
    display: none;
  }

  .y-grid-line {
    stroke: black !important;
    opacity: 0.3 !important;
    stroke-dasharray: 0;
  }

  .c3-ygrid-line {
    &.aging-warning line {
      stroke-dasharray: 8;
      stroke: @color-status-yellow;
      stroke-width: 3;
    }

    &.aging-alarm line {
      stroke-dasharray: 8;
      stroke: @color-status-orange;
      stroke-width: 3;
    }
  }

  .c3-region.aging-warning {
    fill: @color-status-yellow;
  }

  .c3-region.aging-alarm {
    fill: @color-status-orange;
  }

  .c3-chart-bars .c3-chart-bar path {
    stroke-width: 2px;
    stroke-linecap: round !important;
    opacity: 0.9;
  }

  &--total {
    .c3-chart-bars .c3-chart-bar path {
      fill: #bdb8d3 !important;
      stroke: #7b72a7 !important;
    }
  }
  &--fault {
    .c3-chart-bars .c3-chart-bar path {
      fill: #a3cfc8 !important;
      stroke: #46a092 !important;
    }
  }
}
